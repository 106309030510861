import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Modal from "../components/modal";
import axios from "axios";
import {
  ModalLoading,
  ModalError,
  ModalSuccess,
} from "../components/modal-states";
import ReactGA from "react-ga";

const IndexPage = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [housingName, setHousingName] = useState("");

  const [emailHasError, setEmailHasError] = useState(false);
  const [nameHasError, setNameHasError] = useState(false);
  const [housingNameHasError, setHousingNameHasError] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    ReactGA.initialize("UA-162055246-1");
    ReactGA.pageview("/splash");
  }, []);

  const submitForm = () => {
    const formIsValid = validateForm();

    if (formIsValid) {
      ReactGA.event({
        category: "User",
        action: "Sent Interest",
      });
      setIsloading(true);
      setFormSubmitted(true);
      axios
        .post("https://mandrillapp.com/api/1.0/messages/send.json", {
          key: "MQeHItFY9Iqqd0OqM1UosA",
          message: {
            from_email: "splash@grannskap.se",
            from_name: "Intresseanmälan Grannskap",
            to: [
              {
                email: "support@grannskap.se",
                name: "Grannskap Support",
                type: "to",
              },
            ],
            headers: {
              "Reply-To": email,
            },
            autotext: "true",
            subject: "Intresseanmälan - Grannskap",
            html: `Namn: ${name}<br />E-post: ${email}<br />Brf: ${housingName}`,
          },
        })
        .then(function(response) {
          setIsloading(false);
        })
        .catch(function(error) {
          setIsloading(false);
          setHasError(true);
        });
    }
  };

  const validateForm = () => {
    setNameHasError(name === "" ? true : false);
    setEmailHasError(email === "" ? true : false);
    setHousingNameHasError(housingName === "" ? true : false);

    return name !== "" && email !== "" && housingName !== "";
  };

  const handleShowModal = () => {
    ReactGA.event({
      category: "User",
      action: "Open Interest Modal",
    });
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setFormSubmitted(false);
    setHasError(false);
    setEmail("");
    setName("");
    setHousingName("");
  };

  const handleGoToAccount = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Account Link",
    });
    window.location = "https://konto.grannskap.se/";
  };

  return (
    <Layout>
      <SEO
        title="Hemma på nätet"
        description="Grannskap® är en digital plattform för grannsämja och grannsamverkan."
        author="Grannskap AB"
      />
      <div className="py-12 bg-white overflow-hidden md:pb-12 rounded-sm shadow-lg">
        <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 373.61 204.92"
              className="w-64 h-64 mx-auto"
            >
              <path
                style={{ fill: "#0B7A6F" }}
                d="M113.24,308.31c-.6,15.55-11.11,24.46-23.56,24.46-13.36,0-26.48-11.41-26.48-27.9S76.25,277,89.6,277a22.21,22.21,0,0,1,13.58,4.37c1.73,1.25,2.63,2.35,2.63,3.91a2.88,2.88,0,0,1-2.93,3,5,5,0,0,1-3.15-1.49,16.44,16.44,0,0,0-10.2-3.51c-10,0-19.73,9.06-19.73,21.64s9.68,21.65,19.88,21.65c8.85,0,16.43-6.18,17.33-16.49H94.48a2.82,2.82,0,1,1,0-5.63h15.46C112.26,304.4,113.31,306,113.24,308.31Z"
                transform="translate(-63.2 -142.54)"
              />
              <path
                style={{ fill: "#0B7A6F" }}
                d="M143.92,294.24c0,1.88-1.43,3.13-4,3.13a13.07,13.07,0,0,0-12.61,10.71v21.25a3.11,3.11,0,1,1-6.22,0V294.64a3.11,3.11,0,1,1,6.22,0V301c.45-2.65,5.26-9.76,13.28-9.76C142.72,291.2,143.92,292.53,143.92,294.24Z"
                transform="translate(-63.2 -142.54)"
              />
              <path
                style={{ fill: "#0B7A6F" }}
                d="M178.35,299.32v-4.68a3.24,3.24,0,0,1,3.08-3.29,3.19,3.19,0,0,1,3.15,3.29v34.77a3.12,3.12,0,1,1-6.23,0v-5.63c-1.5,4.77-6.67,9-13.35,9-8.33,0-18.46-6.8-18.46-20.86s10.13-20.79,18.46-20.79A14.31,14.31,0,0,1,178.35,299.32Zm.3,12.59c0-10.71-6.9-15.16-12.75-15.16-6.08,0-13.13,4.92-13.13,15.16s7,15.15,13.13,15.15C171.75,327.06,178.65,322.38,178.65,311.91Z"
                transform="translate(-63.2 -142.54)"
              />
              <path
                style={{ fill: "#0B7A6F" }}
                d="M228.47,308.62v20.71a3.29,3.29,0,0,1-3.15,3.28,3.22,3.22,0,0,1-3.08-3.28V308.62c0-8.51-5-11.72-10.2-11.72-7.65,0-11.55,6.72-12.23,11.18v21.25a3.12,3.12,0,1,1-6.23,0V294.64a3.12,3.12,0,1,1,6.23,0V301c.45-2.65,5.1-9.76,14-9.76C220.89,291.2,228.47,295.65,228.47,308.62Z"
                transform="translate(-63.2 -142.54)"
              />
              <path
                style={{ fill: "#0B7A6F" }}
                d="M272.36,308.62v20.71a3.29,3.29,0,0,1-3.15,3.28,3.22,3.22,0,0,1-3.08-3.28V308.62c0-8.51-4.95-11.72-10.2-11.72-7.66,0-11.56,6.72-12.23,11.18v21.25a3.12,3.12,0,1,1-6.23,0V294.64a3.12,3.12,0,1,1,6.23,0V301c.45-2.65,5.1-9.76,14-9.76C264.78,291.2,272.36,295.65,272.36,308.62Z"
                transform="translate(-63.2 -142.54)"
              />
              <path
                style={{ fill: "#0B7A6F" }}
                d="M306.57,297.45a4.85,4.85,0,0,1,.75,2.5,2.41,2.41,0,0,1-2.55,2.5c-1,0-1.88-.47-2.93-1.95a8.83,8.83,0,0,0-7.8-4c-4.5,0-7.28,2.35-7.28,5.78,0,2.58,1.65,4.77,6.08,5.94l4.8,1.25c6.68,1.88,11.25,4.85,11.25,11.1,0,7.89-7.2,12-15,12-6.38,0-11.48-3-13.28-6.72a6.37,6.37,0,0,1-1.13-3.2A2.56,2.56,0,0,1,282,320a2.93,2.93,0,0,1,2.7,2c1.73,3.67,5.26,5.15,9.16,5.15,4.87,0,8.85-2.11,8.85-6.09,0-3.13-2.25-4.85-6.23-5.94l-5.62-1.56c-7.06-2-10.21-6-10.21-11.1,0-6.8,5.93-11.25,13.36-11.25C299.66,291.12,304.17,293.62,306.57,297.45Z"
                transform="translate(-63.2 -142.54)"
              />
              <path
                style={{ fill: "#0B7A6F" }}
                d="M349.1,329.8a2.75,2.75,0,0,1-2.7,2.81,2.59,2.59,0,0,1-2-.86l-16.21-17.81-5.92,6.17v9.22a3.12,3.12,0,1,1-6.23,0v-49.7a3.12,3.12,0,1,1,6.23,0V312.3l18.83-20a2.8,2.8,0,0,1,2.1-.94,2.91,2.91,0,0,1,2.85,3,2.73,2.73,0,0,1-.83,2L332.07,310l16.21,17.74A3,3,0,0,1,349.1,329.8Z"
                transform="translate(-63.2 -142.54)"
              />
              <path
                style={{ fill: "#0B7A6F" }}
                d="M383.54,299.32v-4.68a3.12,3.12,0,1,1,6.23,0v34.77a3.12,3.12,0,1,1-6.23,0v-5.63c-1.5,4.77-6.68,9-13.36,9-8.32,0-18.45-6.8-18.45-20.86s10.13-20.79,18.45-20.79A14.32,14.32,0,0,1,383.54,299.32Zm.3,12.59c0-10.71-6.9-15.16-12.76-15.16-6.07,0-13.12,4.92-13.12,15.16s7.05,15.15,13.12,15.15C376.94,327.06,383.84,322.38,383.84,311.91Z"
                transform="translate(-63.2 -142.54)"
              />
              <path
                style={{ fill: "#0B7A6F" }}
                d="M436.8,311.91c0,14.06-10.12,20.86-18.45,20.86-6.6,0-11.71-4.06-13.28-8.75v20.16a3.28,3.28,0,0,1-3.15,3.28,3.23,3.23,0,0,1-3.15-3.28V294.64a3.19,3.19,0,0,1,3.15-3.29,3.23,3.23,0,0,1,3.07,3.29v4.68a14.33,14.33,0,0,1,13.36-8.2C426.68,291.12,436.8,298,436.8,311.91Zm-6.22,0c0-10.24-7.05-15.16-13.13-15.16-5.85,0-12.76,4.45-12.76,15.16,0,10.47,6.91,15.15,12.76,15.15C423.53,327.06,430.58,322.22,430.58,311.91Z"
                transform="translate(-63.2 -142.54)"
              />
              <path
                style={{ fill: "#0B7A6F" }}
                d="M254,221.32a12,12,0,0,0,12-12V182.7a9.07,9.07,0,0,0-.06-1l-1.88,1.88-3.86,3.86-.43.43v21.46a5.8,5.8,0,0,1-5.8,5.8H226.47l-17.42,17.42V215.09h-8.78a4.91,4.91,0,0,1-4.9-4.9V182.7a2.7,2.7,0,0,1,.79-1.89l31.43-31.29,26.51,26.39,4.41-4.41-27.8-27.68a4.42,4.42,0,0,0-6.23,0L191.76,176.4a8.91,8.91,0,0,0-2.62,6.3v27.49a11.14,11.14,0,0,0,11.13,11.13h2.55v18.13a3.33,3.33,0,0,0,2.07,3.1,3.23,3.23,0,0,0,1.29.26,3.37,3.37,0,0,0,2.37-1l20.5-20.51H234"
                transform="translate(-63.2 -142.54)"
              />
              <path
                style={{ fill: "#0B7A6F" }}
                d="M308.24,197.84l-32.72-32.57a4.42,4.42,0,0,0-6.23,0l-4.47,4.45-4.05,4-4.41,4.39-5.23,5.21-14.55,14.48a9,9,0,0,0-2.62,6.31V212h6.23v-7.82a2.69,2.69,0,0,1,.78-1.89l14.57-14.5,4.27-4.25,5.12-5.1,4.3-4.28,3.18-3.17,31.43,31.3a2.7,2.7,0,0,1,.79,1.89v27.49a4.91,4.91,0,0,1-4.9,4.9H291V254l-17.42-17.42H246a5.8,5.8,0,0,1-5.8-5.8v-6.3H234v6.3a12,12,0,0,0,12,12h25l20.5,20.51a3.37,3.37,0,0,0,2.37,1,3.23,3.23,0,0,0,1.29-.26,3.34,3.34,0,0,0,2.07-3.1V242.77h2.55a11.14,11.14,0,0,0,11.13-11.13V204.15A9,9,0,0,0,308.24,197.84Z"
                transform="translate(-63.2 -142.54)"
              />
            </svg>
            <blockquote>
              <div className="max-w-2xl mx-auto mb-6 text-center text-lg leading-8 font-bold text-gray-900 ">
                <p className="mb-2">
                  Grannskap® är en digital plattform för grannsämja och
                  grannsamverkan. Vår vision är bostadsområden där digital
                  närvaro och digital samverkan lever i symbios med den
                  traditionella kontakten och gemenskapen grannar emellan.
                </p>
              </div>
              <div className="max-w-2xl mx-auto text-center text-base leading-7 font-medium text-gray-900">
                <p className="mb-2">
                  Snart lanserar vi vår nya webbapplikation för
                  bostadsrättsföreningar - en komplett digital lösning för både
                  styrelsearbete och boende.
                </p>

                <p>
                  Är du och dina grannar hemma på nätet? Välkommen till ditt
                  Grannskap®!
                </p>
              </div>
            </blockquote>
            <div className="flex justify-center mt-16 my-4">
              <span className="inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  className="inline-flex items-center px-6 py-3 mx-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary hover:bg-primary-light focus:outline-none focus:border-primary-light focus:shadow-outline-indigo active:bg-primary-light transition ease-in-out duration-150"
                  onClick={() => handleShowModal()}
                >
                  Anmäl intresse
                </button>
              </span>
              <button
                type="button"
                className="inline-flex items-center px-6 py-3 mx-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-secondary hover:bg-secondary-light focus:outline-none focus:border-secondary-light focus:shadow-outline-secondary-light active:bg-secondary-light transition ease-in-out duration-150"
                onClick={() => handleGoToAccount()}
              >
                Mina sidor
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal open={showModal}>
        <>
          {formSubmitted ? (
            <>
              {hasError && <ModalError closeModal={closeModal} />}
              {isLoading && <ModalLoading closeModal={closeModal} />}
              {!isLoading && !hasError && (
                <ModalSuccess closeModal={closeModal} />
              )}
            </>
          ) : (
            <>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Häng med!
                </h3>
                <div className="mt-4 mb-8">
                  <p className="mb-2 text-sm leading-5 text-gray-700">
                    Snart lanserar vi vår helt nya plattform för
                    bostadsrättsföreningar. Fyll i dina uppgifter här nedan så
                    hör vi av oss när det är dags för beta!
                  </p>
                </div>
              </div>

              <div>
                <div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      E-post *
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        name="email"
                        className={
                          emailHasError
                            ? "appearance-none block w-full px-3 py-2 border border-red-300 rounded-md placeholder-red-300 focus:outline-none focus:shadow-outline-red focus:border-red-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            : "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        }
                        id="email"
                      />
                      {emailHasError && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <svg
                            className="h-5 w-5 text-red-500"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                    {emailHasError && (
                      <p className="mt-2 text-sm text-red-600">
                        Obligatoriskt fält.
                      </p>
                    )}
                  </div>
                  <div className="mt-2">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Ditt namn *
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        type="text"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        name="name"
                        className={
                          nameHasError
                            ? "appearance-none block w-full px-3 py-2 border border-red-300 rounded-md placeholder-red-300 focus:outline-none focus:shadow-outline-red focus:border-red-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            : "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        }
                        id="name"
                      />
                      {nameHasError && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <svg
                            className="h-5 w-5 text-red-500"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                    {nameHasError && (
                      <p className="mt-2 text-sm text-red-600">
                        Obligatoriskt fält.
                      </p>
                    )}
                  </div>
                  <div className="mt-2">
                    <label
                      htmlFor="housing-name"
                      className="block text-sm font-medium leading-5 text-gray-700"
                    >
                      Föreningens namn *
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <input
                        type="text"
                        value={housingName}
                        onChange={e => setHousingName(e.target.value)}
                        name="housing-name"
                        className={
                          housingNameHasError
                            ? "appearance-none block w-full px-3 py-2 border border-red-300 rounded-md placeholder-red-300 focus:outline-none focus:shadow-outline-red focus:border-red-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            : "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        }
                        id="housing-name"
                      />
                      {housingNameHasError && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <svg
                            className="h-5 w-5 text-red-500"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                    {housingNameHasError && (
                      <p className="mt-2 text-sm text-red-600">
                        Obligatoriskt fält.
                      </p>
                    )}
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <span className="flex w-full rounded-md shadow-sm">
                      <input
                        type="submit"
                        value="Skicka"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-primary text-base leading-6 font-medium text-white shadow-sm cursor-pointer hover:bg-primary-light focus:outline-none focus:bg-primary-light focus:shadow-outline-primary-light transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        onClick={submitForm}
                        readOnly
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <span className="flex w-full rounded-md shadow-sm">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-secondary text-base leading-6 font-medium text-white shadow-sm hover:bg-secondary-light focus:outline-none focus:bg-secondary-light focus:shadow-outline-secondary-light transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onClick={closeModal}
                  >
                    Stäng
                  </button>
                </span>
              </div>
            </>
          )}
        </>
      </Modal>
    </Layout>
  );
};

export default IndexPage;
